export function hasAddon(props) {
  return !!(props.addonBefore || props.addonAfter);
}
export function hasPrefixSuffix(props) {
  return !!(props.prefix || props.suffix || props.allowClear);
}
export function resolveOnChange(target, e, onChange, targetValue) {
  if (!onChange) {
    return;
  }
  var event = e;
  if (e.type === 'click') {
    // Clone a new target for event.
    // Avoid the following usage, the setQuery method gets the original value.
    //
    // const [query, setQuery] = React.useState('');
    // <Input
    //   allowClear
    //   value={query}
    //   onChange={(e)=> {
    //     setQuery((prevStatus) => e.target.value);
    //   }}
    // />

    // A bug report filed on WebKit's Bugzilla tracker, dating back to 2009, specifically addresses the issue of cloneNode() not copying files of <input type="file"> elements.
    // As of the last update, this bug was still marked as "NEW," indicating that it might not have been resolved yet​​.
    // https://bugs.webkit.org/show_bug.cgi?id=28123
    var currentTarget = target.cloneNode(true);

    // click clear icon
    event = Object.create(e, {
      target: {
        value: currentTarget
      },
      currentTarget: {
        value: currentTarget
      }
    });
    currentTarget.value = '';
    onChange(event);
    return;
  }

  // Trigger by composition event, this means we need force change the input value
  // https://github.com/ant-design/ant-design/issues/45737
  // https://github.com/ant-design/ant-design/issues/46598
  if (target.type !== 'file' && targetValue !== undefined) {
    // A bug report filed on WebKit's Bugzilla tracker, dating back to 2009, specifically addresses the issue of cloneNode() not copying files of <input type="file"> elements.
    // As of the last update, this bug was still marked as "NEW," indicating that it might not have been resolved yet​​.
    // https://bugs.webkit.org/show_bug.cgi?id=28123
    var _currentTarget = target.cloneNode(true);
    event = Object.create(e, {
      target: {
        value: _currentTarget
      },
      currentTarget: {
        value: _currentTarget
      }
    });
    _currentTarget.value = targetValue;
    onChange(event);
    return;
  }
  onChange(event);
}
export function triggerFocus(element, option) {
  if (!element) return;
  element.focus(option);

  // Selection content
  var _ref = option || {},
    cursor = _ref.cursor;
  if (cursor) {
    var len = element.value.length;
    switch (cursor) {
      case 'start':
        element.setSelectionRange(0, 0);
        break;
      case 'end':
        element.setSelectionRange(len, len);
        break;
      default:
        element.setSelectionRange(0, len);
    }
  }
}