"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DesignTokenContext", {
  enumerable: true,
  get: function () {
    return _context.DesignTokenContext;
  }
});
Object.defineProperty(exports, "PresetColors", {
  enumerable: true,
  get: function () {
    return _interface.PresetColors;
  }
});
Object.defineProperty(exports, "calc", {
  enumerable: true,
  get: function () {
    return _calc.default;
  }
});
Object.defineProperty(exports, "defaultConfig", {
  enumerable: true,
  get: function () {
    return _context.defaultConfig;
  }
});
Object.defineProperty(exports, "genComponentStyleHook", {
  enumerable: true,
  get: function () {
    return _genComponentStyleHook.default;
  }
});
Object.defineProperty(exports, "genPresetColor", {
  enumerable: true,
  get: function () {
    return _genPresetColor.default;
  }
});
Object.defineProperty(exports, "genStyleHooks", {
  enumerable: true,
  get: function () {
    return _genComponentStyleHook.genStyleHooks;
  }
});
Object.defineProperty(exports, "genSubStyleComponent", {
  enumerable: true,
  get: function () {
    return _genComponentStyleHook.genSubStyleComponent;
  }
});
Object.defineProperty(exports, "getLineHeight", {
  enumerable: true,
  get: function () {
    return _genFontSizes.getLineHeight;
  }
});
Object.defineProperty(exports, "mergeToken", {
  enumerable: true,
  get: function () {
    return _statistic.merge;
  }
});
Object.defineProperty(exports, "statisticToken", {
  enumerable: true,
  get: function () {
    return _statistic.default;
  }
});
Object.defineProperty(exports, "useResetIconStyle", {
  enumerable: true,
  get: function () {
    return _useResetIconStyle.default;
  }
});
Object.defineProperty(exports, "useStyleRegister", {
  enumerable: true,
  get: function () {
    return _cssinjs.useStyleRegister;
  }
});
Object.defineProperty(exports, "useToken", {
  enumerable: true,
  get: function () {
    return _useToken.default;
  }
});
var _cssinjs = require("@ant-design/cssinjs");
var _interface = require("./interface");
var _useToken = _interopRequireDefault(require("./useToken"));
var _genComponentStyleHook = _interopRequireWildcard(require("./util/genComponentStyleHook"));
var _genPresetColor = _interopRequireDefault(require("./util/genPresetColor"));
var _statistic = _interopRequireWildcard(require("./util/statistic"));
var _useResetIconStyle = _interopRequireDefault(require("./util/useResetIconStyle"));
var _calc = _interopRequireDefault(require("./util/calc"));
var _genFontSizes = require("./themes/shared/genFontSizes");
var _context = require("./context");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }